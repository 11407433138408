import styled from "styled-components";
import { Label,Input, Button, Img, Div, H5 } from "../../helpers/elements";

const PrimaryLogoContainer = styled(Div)`height:26px; display:inline-block; vertical-align:middle; color:${props=>props.theme.secondary};`
const ActiveLogo = styled(Img)`height:70px; display:block; margin:0 auto 14px; fill:${props=>props.theme.secondary};`
const Divider = styled.hr`color: ${props=>props.theme.secondary}; border: 1px solid ${props=>props.theme.secondary};	width:100%;	dsplay:block;	height:100px;`



const CheckoutContainer = styled(Div)`background:linear-gradient(180deg, ${props=>props.theme.primary}, ${props=>props.theme.secondary});height:100%; display:flex; justify-content:center; align-items:center;@media(max-width:991px){	display:block;}`
const CheckoutWrapper = styled(Div)`box-shadow:0 4px 4px rgba(0,0,0,.4); background:${props=>props.theme.secondary};display:flex; justify-content:flex-start; align-items:stretch;flex-direction:row;max-width:1280px;border-radius:4px;@media(max-width:991px){
	display:block;}`
const OptionsContainer = styled(Div)`border-radius:4px 0 0 4px;background:#fff; padding:20px; flex:1 33%;`
const PaymentContainer = styled(Div)`border-radius:4px 0 0 4px; flex:2 67%;`
const PaymentWrapper = styled(Div)`padding:0;`
const Foot = styled(Div)`margin-bottom:20px;text-align:center;color:#fff;`
const FormGroup50 = styled(Div)`display:flex; flex-flow:row wrap; justify-content:space-between; align-items:flex-start; margin-bottom:20px; gap:20px;`
const FormGroup75 = styled(Div)`display:flex; flex-flow:row no-wrap; justify-content:space-between; align-items:stretch; margin-bottom:20px;gap:20px;font-size:.9rem;color:#fff;flex:1 auto;
@media(max-width:646px){
	display:block;
	text-align:center;
}
`
const FormGroup100 = styled(Div)`margin-bottom:20px;`
const QuantityContainer = styled(Div)`border:thin solid #fff; border-radius:4px; padding:20px 14px; color:#fff; white-space: nowrap;font-size:1.2rem;text-align:center; flex:1 25%;`
const QuantitySelector = styled(Div)`border:thin solid #fff; border-radius:40px; width:24px; height:24px; background-color:${props=>props.theme.primary}; align-items:center;display:flex;justify-content:center;font-size:.9rem;cursor:pointer;`
const QuantitySelectorContainer = styled(Div)`align-items:center;display:flex;justify-content:center;flex:row wrap; gap:16px; color:#fff; margin-top:10px;`

const SelectedPlanContainer = styled(Div)`align-items:stretch;display:flex;justify-content:space-between;flex:row wrap; gap:16px; color:#fff; margin-top:10px;
@media(max-width:646px){
	display:block;
}
`
const SelectedPlanItem = styled(Div)`background-color:${props=>props.theme.primary}; padding:20px; border-radius:4px; box-shadow:0 4px 6px rgba(0,0,0,.4);flex:1 50%;text-align:center;display:flex;flex-flow:column wrap; justify-content:center;align-items:center;gap:10px 0;
@media(min-width:646px){
	min-width:230px;
}
@media(max-width:646px){
	margin-bottom:20px;
}
`

const LogoContainer = styled(Div)`padding:20px; text-align:center;color:${props=>props.theme.secondary};fill:${props=>props.theme.secondary};`
const PlansDisplayContainer = styled(Div)`padding:20px;`
const PlanDisplay = styled(Div)`border:thin solid ${props=>props.theme.primary}; border-radius:4px; margin-bottom:1.2rem;`
const PlanDisplaySelected = styled(PlanDisplay)`border-color:${props=>props.theme.secondary};`
const PlanDisplayHeader = styled(Div)`background:${props=>props.theme.primary}; color:#fff; padding:1rem; border-radius:4px 4px 0 0;font-size:1.1rem; font-weight:500;`
const PlanDisplayHeaderSelected = styled(PlanDisplayHeader)`background:${props=>props.theme.secondary}; `
const PlanDisplayPricing = styled(Div)`color:${props=>props.theme.primary}; padding:1rem; font-size:1.4rem; font-weight:700;`
const PlanDisplayPricingSelected = styled(PlanDisplayPricing)`color:${props=>props.theme.secondary};`
const PlanDisplayFeatures = styled(Div)``

const FormContainer = styled(Div)`padding:3rem;`



const FormTitle = styled(H5)`color:#fff;font-size:1.2rem;margin:0 0 10px;`
const CheckoutLabel = styled(Label)`color: ${props=>props.theme.label}; margin-bottom: .25rem !important;display:block;`
const CheckoutInput = styled(Input)`width:100%; border: none !important; color: ${props=>props.theme.secondary} !important; padding-left: 0 !important; padding-top: 0 !important; border-bottom: 2px solid #fff !important; border-radius: 0 !important; font-weight: normal; font-size:1rem;&:focus{ box-shadow:none;}; background: transparent !important; color:#fff !important;::placeholder{color:#fff;}::-ms-input-placeholder{color:#fff;} -webkit-autofill{-webkit-box-shadow: 0 0 0px 1000px ${props=>props.theme.secondary} inset; transition: background-color 5000s ease-in-out 0s;}`
const SubmitBtn = styled(Button)`border:thin solid ${props=>props.theme.secondary};	background-color:${props=>props.theme.primary};	color:#fff;padding:10px 40px; box-shadow:0 2px 4px rgba(45,45,45,.4); border-radius:4px;font-size:1.25rem;font-weight:600;	&:focus{ border:thin solid ${props=>props.theme.secondary};	background-color:${props=>props.theme.primary};	color:#fff;	}	&:hover{ box-shadow:0 10px 40px #85BB65;} cursor:pointer;`




export {
	Divider, 
	Foot,
	CheckoutLabel, 
	CheckoutInput, 
	SubmitBtn, 
	ActiveLogo, 
	PrimaryLogoContainer, 
	CheckoutWrapper, 
	CheckoutContainer, 
	OptionsContainer, 
	PaymentWrapper, 
	FormGroup50, 
	FormGroup100,
	FormTitle,
	FormGroup75,
	PaymentContainer,
	QuantityContainer,
	QuantitySelector,
	QuantitySelectorContainer,
	SelectedPlanContainer,
	SelectedPlanItem,
	PlansDisplayContainer,
	PlanDisplay,
	PlanDisplaySelected,
	PlanDisplayHeader,
	PlanDisplayHeaderSelected,
	PlanDisplayPricing,
	PlanDisplayPricingSelected,
	PlanDisplayFeatures,
	FormContainer,
	LogoContainer,
}