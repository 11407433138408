

const validator = {

    validateForm: (data) =>{
        let retval = {success:true, errors:[]};
        if(data.email){
            if(data.email.value.length == 0 && !data.email.value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)){
                retval.success = false; retval.errors.push({message:"Please provide a valid email address", position:"bottom-center"});
            }
        }        
        if(data.first_name){
            if(data.first_name.value.length < 2){
                retval.success = false; retval.errors.push({message:"Please provide a valid first name", position:"bottom-left"});
            }
        }        
        
        if(data.last_name){
            if(data.last_name.value.length < 2){
                retval.success = false; retval.errors.push({message:"Please provide a valid last name", position:"bottom-right"});
            }
        }
        return retval;
    }



}

export default validator;