import React, { useState, useEffect, useContext } from 'react'

import { LogoContainer, OptionsContainer, PlanDisplay, PlanDisplayHeader, PlanDisplayHeaderSelected, PlanDisplayPricing, PlanDisplayPricingSelected, PlanDisplaySelected, PlansDisplayContainer, PrimaryLogoContainer } from "../../styles/Checkout/main";
import { ActiveLogo } from '../../styles/Checkout/main';
import CurrentSystemCtx from '../../context/CurrentSystemCtx'
import SelectedProductCtx from '../../context/SelectedProductCtx';
import AllProductsCtx from '../../context/AllProductsCtx';
import PrimaryLogo from '../PrimaryLogo';


const OptionsSide = () => {

	const [logoRep, setLogoRep] = useState();
	const { currentSystem } = useContext(CurrentSystemCtx)
	const { selectedProduct, setSelectedProduct } = useContext(SelectedProductCtx)
	const { products } = useContext(AllProductsCtx)

	const handle_product_selection = (evt) => {
		const selected_uuid = evt.currentTarget.getAttribute("uuid");
		let new_product = products.filter((product) => { return product.uuid === selected_uuid });
		setSelectedProduct(new_product[0])
	}

	useEffect(() => {
		if (currentSystem.powered) {
			setLogoRep(<LogoContainer><ActiveLogo src={currentSystem.logo} alt={currentSystem.name} /><small>Powered by</small>&nbsp;<PrimaryLogoContainer><PrimaryLogo /></PrimaryLogoContainer></LogoContainer>)
		} else {
			setLogoRep(<LogoContainer><ActiveLogo src={currentSystem.logo} alt={currentSystem.name} /></LogoContainer>)
		}
	}, [currentSystem])

	return (
		<OptionsContainer>
			{logoRep}
			<PlansDisplayContainer>
				{
					products.map((product, idx) => {
						return product.listable && (

							selectedProduct.uuid === product.uuid ? (
								<PlanDisplaySelected key={idx}>
									<PlanDisplayHeaderSelected>
										<label><input type="radio" name="product-selector" onChange={handle_product_selection} uuid={product.uuid} checked /> Selected Plan</label>
									</PlanDisplayHeaderSelected>
									<PlanDisplayPricingSelected>
										<div>
											{product.frequency}
										</div>
										<div>
											${product.price.toLocaleString()}&nbsp;<span className='text-black small'>/{product.frequency.slice(0, -2)}</span>
										</div>
									</PlanDisplayPricingSelected>
								</PlanDisplaySelected>
							) : (
								<PlanDisplay key={idx}>
									<PlanDisplayHeader>
										<label><input type="radio" name="product-selector" onChange={handle_product_selection} uuid={product.uuid} /> Choose This Plan</label>
									</PlanDisplayHeader>
									<PlanDisplayPricing>
										<div>
											{product.frequency}
										</div>
										<div>
											${product.price.toLocaleString()}&nbsp;<span className='text-black small'>/{product.frequency.slice(0, -2)}</span>
										</div>
									</PlanDisplayPricing>
								</PlanDisplay>
							)
						)

					})
				}
			</PlansDisplayContainer>
		</OptionsContainer>
	)
}

export default OptionsSide;