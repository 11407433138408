import React from 'react'
import { createRoot } from 'react-dom/client';
import { StripeSecretProvider } from './context/StripeSecret'
import { AllProductsProvider } from './context/AllProductsCtx'
import { SelectedProductProvider } from './context/SelectedProductCtx'
import { MountProvider } from './context/MountCtx'
import { CurrentSystemProvider } from './context/CurrentSystemCtx'


import App from './App'


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <MountProvider>
            <CurrentSystemProvider>
                <StripeSecretProvider>
                    <AllProductsProvider>
                        <SelectedProductProvider>
                            <App />
                        </SelectedProductProvider>
                    </AllProductsProvider>
                </StripeSecretProvider>
            </CurrentSystemProvider>
        </MountProvider>
    </React.StrictMode>
)
