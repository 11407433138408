import React, { useState } from 'react'

const CurrentSystemCtx = React.createContext()

export const CurrentSystemProvider = ({ children }) => {

    const [currentSystem, setCurrentSystem] = useState()

    return (
        <CurrentSystemCtx.Provider value={{ currentSystem, setCurrentSystem }}>
            {children}
        </CurrentSystemCtx.Provider>
    )

}

export default CurrentSystemCtx