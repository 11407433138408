import React, { useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import SelectedProductCtx from '../context/SelectedProductCtx';
import CurrentSystemCtx from '../context/CurrentSystemCtx'
import StripeSecretCtx from '../context/StripeSecret'
import AllProductCtx from '../context/AllProductsCtx'
import PaymentSide from '../components/Checkout/PaymentSide'
import OptionsSide from '../components/Checkout/OptionsSide'
import productHelpers from '../helpers/products'
import stripeHelpers from '../helpers/stripe'
import Layout from '../layouts/Checkout'


const LaunchCheckout = (props) => {

	const theme = props.system.theme;
	const { setCurrentSystem } = useContext(CurrentSystemCtx)
	const { setSelectedProduct } = useContext(SelectedProductCtx)
	const { setProducts } = useContext(AllProductCtx)
	const { setStripeSecret } = useContext(StripeSecretCtx)
	const [ mount, setMount ] = useState(false);


	const get_current_system = async () => {
		setCurrentSystem(props.system);
		let selected_product;
		if (localStorage.getItem("selected_product_id") != null) {
			const system_product = await productHelpers.get_product(localStorage.getItem("selected_product_id"));
			selected_product = system_product;
		} else {
			let system_products = await productHelpers.get_products(props.system.identifier);
			setProducts(system_products);
			await Promise.all(system_products.map((product) => {
				if (product.is_default) {
					selected_product = product;
				}
			}));
		}

		setSelectedProduct(selected_product);
		setMount(true);
		/*
			For now, to simpify things, we are not using payment intent API we will used legacy Token API (Stripe).  Later we will revisit payent intent
			Subscription set up plus charge for funnels puls needing webhooks make this more than we need to get this off the ground
		*/
		//await get_client_secret(selected_product);
	}

	
	const get_client_secret = async (product_id) => {
		//::TODO -- if no product in local storage rediret back to options page for this checkout
		const cli_secret = await stripeHelpers.fetchClientSecret(product_id);
		setStripeSecret(cli_secret.secret);
		setMount(true);
	}

	const handleMyLoad = async () => await get_current_system();

	useEffect(() => {
		handleMyLoad()
	}, []);

	return (
		<ThemeProvider theme={theme}>
			{	mount && (<Layout>
				<OptionsSide />
				<PaymentSide />
			</Layout>) } 
		</ThemeProvider>
	)
}
export default LaunchCheckout;