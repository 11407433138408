import React, { useContext, useState, useEffect, useRef } from 'react'
import {
    CardElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import StripeSecretContext from '../../context/StripeSecret'
import validator from '../../helpers/validator'
import { CheckoutInput, CheckoutLabel, SubmitBtn, FormGroup50, FormGroup100, FormTitle, FormGroup75, Foot, QuantityContainer, QuantitySelector, QuantitySelectorContainer, SelectedPlanContainer, SelectedPlanItem, FormContainer } from "../../styles/Checkout/main";
import 'react-toastify/dist/ReactToastify.css';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import SelectedProductCtx from '../../context/SelectedProductCtx';
import AllProductsCtx from '../../context/AllProductsCtx';
import axios from 'axios';
// import updateTotal from '../../hooks/useUpdateTotal';
/**
 * Custom payment form integrated with stripe elements (specifically, the CardNumberElement, CardExpiryElement, and CardCvcElement components from react-stripe-js)
 *
 * @component
 * @example
 * return (
 *   <PaymentForm />
 * )
*/

const PaymentForm = () => {

    const navigator = useNavigate()

    const stripe = useStripe()
    const elements = useElements()
    const email = useRef();
    const first_name = useRef();
    const last_name = useRef()

    const { stripeSecret } = useContext(StripeSecretContext)
    const { selectedProduct } = useContext(SelectedProductCtx)
    const { products } = useContext(AllProductsCtx)

    const [submitting, setSubmitting] = useState(false)
    const [numbFunnels, setNumbFunnels] = useState(0)
    const [hasFunnels, setHasFunnels] = useState(Array.from(products.filter((product) => { return product.frequency === "once" })).length > 0)
    const [funnelsOption, setFunnelsOption] = useState(products.filter((product) => { return product.frequency === "once" }))
    const [total, setTotal] = useState(0)
    const [processingPayment, setProcessingPayment] = useState(false)

    const handle_add_funnels = () => {
        let next_i = numbFunnels + 1;
        setNumbFunnels(next_i);
        updateTotal(next_i)
    }
    const handle_remove_funnels = () => {
        if (numbFunnels > 0) {
            let next_i = numbFunnels - 1;
            setNumbFunnels(next_i);
            updateTotal(next_i)
        }
    }

    const updateTotal = (increment) => {
        let plan_amt = selectedProduct.price;
        let additional_amount = hasFunnels ? 5000 : 0;
        let total_due = plan_amt + (additional_amount * (increment));
        setTotal(total_due)
    }

    const error_message = async (valid) => {
        return (<div>{await Promise.all(valid.errors.map((er, idx) => {
            return <p key={idx}>{er.message}</p>;
        }))} </div>)
    }


    const handle_payment_submit = async evt => {
        evt.preventDefault()
        setSubmitting(true)

        if (!stripe || !elements) {
            toast.error("There was a problem with our payment system. Please refresh the page and try again.", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setSubmitting(false)
            return
        }
        const valid = validator.validateForm({ email: { value: email.current.value }, first_name: { value: first_name.current.value }, last_name: { value: last_name.current.value } });

        if (!valid.success) {
            toast.error(await error_message(valid), {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });

            setSubmitting(false)
        } else {
            setProcessingPayment(true);
            await processPayment();
        }
    }


    const processPayment = async () => {
        const card_elem = elements.getElement(CardElement)
        const payment_method = await stripe.createPaymentMethod({
            type:"card",
            card:card_elem
        });
        console.log(payment_method);
        console.log(payment_method.paymentMethod.id);
        let products = [];
        products.push({
            uuid:selectedProduct.uuid,
            qty:1
        });
        if(hasFunnels){
            products.push({
                uuid:funnelsOption[0].uuid,
                qty:numbFunnels
            });
        }
        const payload = {
            email: email.current.value,
            name: `${first_name.current.value} ${last_name.current.value}`,
            payment_id: payment_method.paymentMethod.id,            
            products:products
        }

        const payment_rez = await axios({
            method:"POST",
            url:"https://api.zealinnovations.com/stripe/purchase/payment",
            data:payload,
            headers:{
                "Content-Type":"application/json"
            }
        }).catch((er)=>{
            console.log(er);
            toast.error(er.message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setSubmitting(false);
        })
        console.log(payment_rez.data);
        if(payment_rez.data.sub_sec && payment_rez.data.intent_status !== "succeeded"){
            const confirm = await stripe.confirmCardPayment(payment_rez.data.sub_sec, {
                payment_method:{
                    card:card_elem,
                    billing_details:{
                        name:payload.name,
                        email:payload.email
                    }
                }
            });
            console.log(confirm);
        }


        // const paymentOptions = {
        //     payment_method: {
        //         card: elements.getElement(CardElement),
        //         billing_details: {
        //             name: `${first_name.current.value} ${last_name.current.value}`,
        //             email: email.current.value
        //         }
        //     },
        //     // return_url: returnUrl,
        //     receipt_email: email.current.value
        // }
        // const paymentRes = await stripe.confirmCardPayment(stripeSecret, paymentOptions)
        // if (paymentRes.error) setToast('Payment failed! Please try again.')
        // else if (paymentRes.paymentIntent.status === 'succeeded') navigator(`/thanks?purchase_completed=true&plan_purchased=${selectedPlan.name}`)

        setSubmitting(false)
        setProcessingPayment(false)


    }

    useEffect(() => {
        updateTotal(numbFunnels);
    }, [selectedProduct])

    const input_sty = {
        base: {
            iconColor: '#fff',
            color: '#fff',
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#fff',
            },
            '::placeholder': {
                color: '#fff',
            }
        }

    }
    return (
        <FormContainer className="no-select">
            <form onSubmit={handle_payment_submit}>
                <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                <SelectedPlanContainer>
                    <SelectedPlanItem>
                        <div className='text-center'>Selected Plan <FontAwesomeIcon icon={faCircleCheck} className="v-align-middle" /></div>
                        <div>
                            <span className='current-price'>${selectedProduct.price.toLocaleString()}</span> /{selectedProduct.frequency.slice(0, -2).toLowerCase()}
                        </div>
                        <div>
                            {selectedProduct.frequency}&nbsp;<sup>*</sup><p></p>
                        </div>
                    </SelectedPlanItem>
                    <SelectedPlanItem>
                        <div>Due Today&nbsp;<sup>**</sup></div>
                        <div className='current-price'>${total.toLocaleString()}</div>
                        <div><small className='small'><sup>**</sup>Recurring payments will be billed from the card you check out with</small></div>
                    </SelectedPlanItem>
                </SelectedPlanContainer>
                <div className='my-4 text-white text-center smaller'>
                    <sup>*</sup>When choosing a monthly plan you are agreeing to a year long contract where cancellation fees may apply.
                </div>
                {
                    hasFunnels && (
                        <FormGroup75>
                            <div className='flex-75'>
                                <FormTitle>
                                    Need custom funnels?
                                </FormTitle>
                                <p>
                                    We can build tailored funnels that fully integrate with Zeal Launch. Pay once and use the funnel on UNLIMITED campaigns. $5,000 per funnel.
                                </p>
                            </div>
                            <QuantityContainer>
                                <div>
                                    Add Funnels
                                </div>
                                <QuantitySelectorContainer>
                                    <QuantitySelector onClick={handle_remove_funnels}><FontAwesomeIcon icon={faMinus} /></QuantitySelector>
                                    <div style={{minWidth:"14px"}}>{numbFunnels}</div>
                                    <QuantitySelector onClick={handle_add_funnels}><FontAwesomeIcon icon={faPlus} /></QuantitySelector>
                                </QuantitySelectorContainer>
                            </QuantityContainer>
                        </FormGroup75>
                    )
                }

                <FormGroup50>
                    <div style={{ flex: "1 auto" }}>
                        <CheckoutLabel htmlFor="firstName" className="form-label text-nowrap flex-50">First Name</CheckoutLabel>
                        <CheckoutInput type="text" className="form-control" id="firstName" placeholder="First Name" ref={first_name} />
                    </div>
                    <div style={{ flex: "1 auto" }}>
                        <CheckoutLabel htmlFor="lastName" className="form-label text-nowrap flex-50">Last Name</CheckoutLabel>
                        <CheckoutInput type="text" className="form-control" id="lastName" placeholder="Last Name" ref={last_name} />
                    </div>
                </FormGroup50>
                <FormGroup100>
                    <CheckoutLabel htmlFor="email" className="form-label text-nowrap">Email Address</CheckoutLabel>
                    <CheckoutInput type="email" className="form-control" id="email" placeholder="Your Email Address" ref={email} />
                </FormGroup100>
                <FormGroup100>
                    <CardElement options={{ style: input_sty }} className="mt-5 stripe-con" />
                </FormGroup100>
                <FormGroup100 className="text-center">
                    <SubmitBtn id="submitBtn" type="submit" disabled={submitting}>
                        {submitting ? <>Processing...</> : <>Pay Now</>}
                    </SubmitBtn>
                </FormGroup100>
            </form>
            <Foot>
                <small>
                    &copy; Zeal Innovations, 2022 &amp; beyond. All Rights Reserved.
                </small>
            </Foot>
        </FormContainer>
    )

}

export default PaymentForm