import React, { useState } from 'react';

const StripeSecretContext = React.createContext();

export const StripeSecretProvider = ({ children }) => {

    const [stripeSecret, setStripeSecret] = useState(null)

    return (
        <StripeSecretContext.Provider value={{ stripeSecret, setStripeSecret }}>
            {children}
        </StripeSecretContext.Provider>
    )

}

export default StripeSecretContext;