import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {stripePk} from "../../env"


import StripeSecretContext from '../../context/StripeSecret'
import { PaymentContainer } from '../../styles/Checkout/main'
import PaymentForm from './PaymentForm';

const PaymentSide = (props) => {

  const [stripePromise, setStripePromise] = useState(() => loadStripe(stripePk))
  return (
    <Elements stripe={stripePromise}>
      <PaymentContainer>
        <PaymentForm />
      </PaymentContainer>
    </Elements>
  )

}

export default PaymentSide;