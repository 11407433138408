const PrimaryLogo = () =>{

	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 796.8 134.4" width="100%" height="100%">
	 <g>
		 <g>
			 <g>
				 <path d="M52.8,6.9c1,0.6,2.2,1.1,2.9,2c2,2.4,1,5.8-1.9,7.2c-3.8,1.9-7.7,3.7-11.5,5.6c-3.2,1.5-6.4,3.1-9.6,4.6
					 c-3.2,1.5-6.5-0.2-7-3.6c-0.3-2,0.8-4,3-5.1c4-2,8.1-3.9,12.1-5.9c3.4-1.6,6.8-3.2,10.2-4.9C51.5,6.9,52.2,6.9,52.8,6.9z"/>
				 <path d="M54.7,94.4c3.9,0.9,5.5,2.9,5.5,7c0,1.5,0,3.1,0,4.6c0,3.7-2,5.9-5.7,6.4c-0.1,0-0.3,0.1-0.5,0.1
					 c-0.2,3.7-1.6,6.9-4.4,9.4c-4,3.4-8.5,4.4-13.4,2.5c-4.8-1.8-7.6-5.5-8.3-10.6c-0.1-1-0.3-1.4-1.5-1.5c-2.8-0.2-5.1-2.7-5.2-5.6
					 c-0.1-2.1-0.1-4.2,0-6.2c0.1-3.2,2.3-5.6,5.6-6c0.2,0,0.4-0.1,0.5-0.1c0.6-2.1,0.4-2.7-1.3-3.7c-5.2-3.1-10.3-6.3-15.5-9.4
					 c-1.7-1-2.7-2.5-2.6-4.4c0-2.1,1.2-3.5,3-4.4c18.8-8.9,37.6-17.7,56.5-26.6c2.7-1.3,5.5-0.3,6.7,2.1c1.2,2.5,0.1,5.2-2.7,6.6
					 C55.5,62,39.7,69.4,23.9,76.9c-0.3,0.2-0.7,0.3-1.1,0.6c0.4,0.3,0.6,0.4,0.9,0.6c3.5,2.1,7,4.3,10.5,6.4c2,1.2,2.9,2.8,2.8,5
					 c0,1.6,0,3.2,0,4.8c2.6,0,5.2,0,8,0c0-1.4,0-2.7,0-4.1c-0.1-2.7,1.1-4.6,3.6-5.7c6.2-2.8,12.4-5.7,18.6-8.6
					 c1.3-0.6,2.5-0.9,3.9-0.5c1.9,0.6,3,1.8,3.3,3.7c0.3,2-0.4,3.6-2.1,4.8c-0.4,0.3-0.9,0.5-1.4,0.7c-4.9,2.3-9.8,4.5-14.8,6.8
					 C54.5,92.4,54.5,92.4,54.7,94.4z"/>
				 <path d="M8.1,45.3c0.1-1.9,1.1-3.4,3-4.3c4.7-2.2,9.4-4.5,14-6.7C39.1,27.7,53,21,66.9,14.4c1-0.5,2.1-0.8,3.2-0.9
					 c2.1-0.1,3.9,1.4,4.4,3.4c0.6,2-0.3,4.2-2.1,5.3c-0.9,0.5-1.8,0.9-2.7,1.4c-18,8.6-36.1,17.2-54.1,25.9c-2.1,1-4.1,1.1-5.9-0.5
					 C8.7,48.1,8.2,46.9,8.1,45.3z"/>
				 <path d="M36.5,78.8c0.1-2.6,1.2-4,3.1-4.9c3.8-1.8,7.6-3.6,11.5-5.4c5.4-2.5,10.8-5.1,16.2-7.6c2.7-1.3,5.4-0.5,6.7,1.9
					 c1.1,2.1,0.5,4.8-1.5,6.1c-0.3,0.2-0.7,0.4-1,0.6c-9.2,4.4-18.5,8.7-27.7,13.1c-2.6,1.2-5.1,0.5-6.5-1.9
					 C36.8,80,36.7,79.2,36.5,78.8z"/>
			 </g>
			 <path d="M7.9,78.6c-0.8-1.7-0.1-3.5,1.7-5.4c4.5-4.7,9-9.3,13.6-14C36.6,45.4,50,31.6,63.5,17.9c0.9-1,2.1-1.9,3.4-2.6
				 c2.4-1.3,5.1-1.1,6.6,0.3c1.5,1.4,1.5,3.7-0.2,5.7c-0.8,0.9-1.7,1.8-2.6,2.7C53.3,42,35.9,59.9,18.5,77.8c-2,2.1-4.3,3.3-7.1,3
				 C9.7,80.7,8.6,80,7.9,78.6z"/>
		 </g>
	 </g>
	 <g>
		 <path d="M94.5,93.3c-1.8,0-3.2-0.5-4.3-1.6c-1.1-1.1-1.6-2.5-1.6-4.2c0-0.9,0.1-1.8,0.5-2.5c0.3-0.7,0.8-1.4,1.3-2L116.5,56H96.8
			 c-1.1,0-2.1-0.1-3-0.4c-0.9-0.2-1.7-0.8-2.2-1.6c-0.6-0.8-0.9-2.2-0.9-4c0-1.9,0.3-3.2,0.9-4.1c0.6-0.8,1.3-1.4,2.2-1.6
			 c0.9-0.2,1.9-0.3,3-0.3h32.3c1.7,0,3.1,0.5,4.2,1.6c1.1,1.1,1.6,2.5,1.6,4.2c0,0.9-0.1,1.7-0.4,2.5c-0.3,0.8-0.7,1.4-1.2,1.8
			 l-25.8,27.2h22.7c1.1,0,2.1,0.1,3,0.4c0.9,0.2,1.6,0.8,2.1,1.6c0.5,0.9,0.8,2.2,0.8,4.1c0,1.8-0.3,3.1-0.9,4
			 c-0.6,0.9-1.3,1.4-2.2,1.6c-0.9,0.2-1.9,0.3-3,0.3H94.5z"/>
		 <path d="M168.2,93.9c-4.3,0-8.1-0.7-11.4-2.1c-3.2-1.4-5.9-3.3-8.1-5.8c-2.2-2.4-3.8-5.2-4.9-8.2c-1.1-3-1.6-6.2-1.6-9.4
			 c0-4.8,1.1-9.1,3.3-12.8c2.2-3.7,5.2-6.7,9.2-8.8c3.9-2.1,8.4-3.2,13.4-3.2c3.6,0,6.8,0.6,9.5,1.7c2.7,1.1,5,2.6,6.9,4.5
			 c1.8,1.8,3.2,3.8,4.2,6c1,2.2,1.4,4.3,1.4,6.4c0,3.5-0.9,6.1-2.5,7.7c-1.7,1.6-3.8,2.4-6.4,2.4h-26.4c0.1,2.3,0.8,4.2,2.1,5.9
			 c1.4,1.7,3.1,3,5.2,3.9c2.1,0.9,4.2,1.4,6.4,1.4c1.7,0,3.1-0.1,4.5-0.3c1.3-0.2,2.4-0.4,3.4-0.8c0.9-0.3,1.8-0.6,2.4-0.9
			 c0.7-0.3,1.4-0.6,2-0.9c0.6-0.3,1.2-0.4,1.7-0.5c0.7-0.1,1.5,0.1,2.2,0.5c0.7,0.4,1.3,1.1,1.8,2.1c0.4,0.7,0.7,1.3,0.9,1.9
			 c0.2,0.6,0.2,1.1,0.2,1.6c0,1.3-0.8,2.6-2.4,3.8c-1.6,1.2-3.9,2.1-6.8,2.9S172.2,93.9,168.2,93.9z M154.8,65.2h20.5
			 c1.1,0,2-0.2,2.6-0.5c0.6-0.4,0.9-1.1,0.9-2.4c0-1.5-0.5-2.9-1.4-4.1c-0.9-1.2-2.2-2.2-3.8-2.9c-1.6-0.7-3.5-1.1-5.6-1.1
			 c-2.5,0-4.7,0.5-6.7,1.5c-2,1-3.6,2.3-4.8,3.9C155.4,61.3,154.8,63.1,154.8,65.2z"/>
		 <path d="M239.3,93.1c-1.9,0-3.4-0.4-4.3-1.1c-0.9-0.7-1.4-1.7-1.4-3l0.7-3.3c-0.5,0.9-1.4,1.9-2.7,3.1c-1.3,1.2-2.9,2.2-5,3.1
			 c-2.1,0.9-4.6,1.4-7.5,1.4c-3.1,0-6.1-0.6-8.9-1.9c-2.8-1.3-5.2-3.1-7.3-5.4c-2.1-2.3-3.8-4.9-5-7.9c-1.2-3-1.9-6.1-1.9-9.5
			 c0-3.3,0.6-6.4,1.9-9.2c1.2-2.9,2.9-5.5,5-7.8c2.1-2.3,4.6-4.1,7.4-5.4c2.8-1.3,5.7-2,8.8-2c2.7,0,5,0.4,7,1.1
			 c1.9,0.7,3.5,1.6,4.8,2.8c1.3,1.1,2.4,2.2,3.3,3.5l-0.6-2.9c0-1.5,0.5-2.6,1.4-3.4c0.9-0.8,2.4-1.1,4.4-1.1c1.9,0,3.2,0.3,4,0.8
			 c0.8,0.6,1.3,1.3,1.5,2.3c0.2,1,0.3,2,0.3,3.2V87c0,1.1-0.1,2.2-0.3,3.1c-0.2,0.9-0.7,1.7-1.5,2.2
			 C242.6,92.8,241.2,93.1,239.3,93.1z M220.9,81.5c2.3,0,4.3-0.6,6.1-1.7c1.8-1.1,3.2-2.7,4.4-4.6c1.1-2,1.6-4.1,1.6-6.6
			 c0-2.6-0.6-4.9-1.7-6.8c-1.1-1.9-2.6-3.5-4.5-4.5c-1.8-1.1-3.9-1.7-6.1-1.7c-2.5,0-4.7,0.6-6.6,1.8c-1.9,1.2-3.5,2.7-4.5,4.7
			 c-1.1,2-1.7,4.2-1.7,6.6c0,2.4,0.6,4.6,1.7,6.5c1.1,1.9,2.7,3.5,4.6,4.6C216.3,80.9,218.5,81.5,220.9,81.5z"/>
		 <path d="M272.4,93.3c-2.5,0-4.6-0.2-6.5-0.5c-1.9-0.3-3.5-0.9-4.9-1.7c-1.4-0.8-2.5-1.9-3.4-3.2c-0.9-1.4-1.5-3-2-5
			 c-0.4-2-0.6-4.4-0.6-7.2V27.4c0-1.1,0.1-2.1,0.4-3.1c0.2-0.9,0.8-1.6,1.6-2.2c0.8-0.6,2.2-0.9,4-0.9c1.9,0,3.2,0.3,4,0.8
			 c0.8,0.5,1.4,1.2,1.6,2.2c0.2,0.9,0.4,1.9,0.4,3v47.8c0,1.3,0.1,2.3,0.2,3.2c0.2,0.8,0.5,1.5,0.9,2c0.4,0.5,1,0.8,1.7,1
			 c0.7,0.2,1.5,0.3,2.5,0.3c0.9,0,1.6,0.1,2.4,0.3c0.7,0.2,1.3,0.7,1.7,1.5c0.4,0.8,0.6,2.1,0.6,3.9c0,1.9-0.2,3.2-0.6,4.1
			 c-0.4,0.9-1,1.4-1.8,1.6C274,93.2,273.2,93.3,272.4,93.3z"/>
	 </g>
	 <g>
		 <path d="M290.5,30.9c-0.9,0-1.7-0.1-2.2-0.4c-0.5-0.3-0.9-0.7-1.1-1.3c-0.2-0.6-0.4-1.4-0.4-2.3c0-0.9,0.1-1.7,0.4-2.2
			 c0.2-0.5,0.6-0.9,1.2-1.1c0.6-0.2,1.3-0.4,2.2-0.4c0.9,0,1.7,0.1,2.2,0.4c0.5,0.3,0.9,0.7,1.1,1.2c0.2,0.6,0.4,1.3,0.4,2.3
			 c0,0.9-0.1,1.6-0.4,2.2c-0.2,0.6-0.6,1-1.1,1.2C292.2,30.8,291.5,30.9,290.5,30.9z M290.5,93c-0.7,0-1.2-0.1-1.7-0.3
			 c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.2-1-0.2-1.8V48.2c0-0.7,0.1-1.3,0.2-1.8c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,1-0.2,1.8-0.2
			 c0.7,0,1.3,0.1,1.7,0.3c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.2,1.1,0.2,1.9v41.7c0,0.7-0.1,1.3-0.2,1.8c-0.2,0.4-0.5,0.7-0.9,0.9
			 C291.8,92.9,291.2,93,290.5,93z"/>
		 <path d="M311.5,92.9c-0.7,0-1.2-0.1-1.6-0.3c-0.4-0.2-0.7-0.5-0.9-1c-0.2-0.4-0.2-1-0.2-1.8v-42c0-0.7,0.1-1.2,0.2-1.5
			 c0.2-0.4,0.5-0.6,0.9-0.8c0.4-0.2,1-0.2,1.6-0.2c0.5,0,0.9,0.1,1.2,0.1c0.3,0.1,0.6,0.2,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.8
			 c0.1,0.3,0.1,0.7,0.1,1.2v5.9c0.9-1.1,1.8-2.2,2.9-3.2c1.1-1,2.2-2,3.6-2.8c1.3-0.8,2.7-1.5,4.3-2c1.6-0.5,3.3-0.7,5.2-0.7
			 c2.9,0,5.5,0.5,8,1.4c2.4,1,4.5,2.4,6.4,4.2c1.8,1.8,3.2,4.1,4.2,6.7c1,2.6,1.5,5.7,1.5,9.1V90c0,0.7-0.1,1.3-0.2,1.8
			 c-0.2,0.4-0.4,0.7-0.9,0.9c-0.4,0.2-1,0.2-1.7,0.2c-0.7,0-1.3-0.1-1.8-0.3c-0.4-0.2-0.7-0.5-0.9-1c-0.2-0.4-0.2-1-0.2-1.8V66.4
			 c0-3.3-0.5-6.1-1.6-8.5c-1.1-2.4-2.7-4.3-4.8-5.6c-2.1-1.3-4.8-2-8.1-2c-3.2,0-6,0.7-8.3,2c-2.3,1.3-4.1,3.2-5.4,5.6
			 c-1.3,2.4-1.9,5.2-1.9,8.5V90c0,0.7-0.1,1.3-0.2,1.8c-0.2,0.4-0.5,0.7-0.9,0.9C312.8,92.8,312.2,92.9,311.5,92.9z"/>
		 <path d="M366.9,92.9c-0.7,0-1.2-0.1-1.6-0.3c-0.4-0.2-0.7-0.5-0.9-1c-0.2-0.4-0.2-1-0.2-1.8v-42c0-0.7,0.1-1.2,0.2-1.5
			 c0.2-0.4,0.5-0.6,0.9-0.8c0.4-0.2,1-0.2,1.6-0.2c0.5,0,0.9,0.1,1.2,0.1c0.3,0.1,0.6,0.2,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.8
			 c0.1,0.3,0.1,0.7,0.1,1.2v5.9c0.9-1.1,1.8-2.2,2.9-3.2c1.1-1,2.2-2,3.6-2.8c1.3-0.8,2.7-1.5,4.3-2c1.6-0.5,3.3-0.7,5.2-0.7
			 c2.9,0,5.5,0.5,8,1.4c2.4,1,4.5,2.4,6.4,4.2c1.8,1.8,3.2,4.1,4.2,6.7c1,2.6,1.5,5.7,1.5,9.1V90c0,0.7-0.1,1.3-0.2,1.8
			 c-0.2,0.4-0.4,0.7-0.8,0.9c-0.4,0.2-1,0.2-1.7,0.2c-0.7,0-1.3-0.1-1.8-0.3c-0.4-0.2-0.7-0.5-0.9-1c-0.2-0.4-0.2-1-0.2-1.8V66.4
			 c0-3.3-0.5-6.1-1.6-8.5c-1.1-2.4-2.7-4.3-4.8-5.6c-2.1-1.3-4.8-2-8.1-2c-3.2,0-6,0.7-8.3,2c-2.3,1.3-4.1,3.2-5.4,5.6
			 c-1.3,2.4-1.9,5.2-1.9,8.5V90c0,0.7-0.1,1.3-0.2,1.8c-0.2,0.4-0.5,0.7-0.9,0.9C368.2,92.8,367.6,92.9,366.9,92.9z"/>
		 <path d="M440.4,93.4c-3.3,0-6.5-0.6-9.4-1.9c-2.9-1.3-5.5-3-7.8-5.2c-2.2-2.2-4-4.8-5.2-7.7c-1.3-2.9-1.9-6.1-1.9-9.4
			 c0-3.4,0.6-6.5,1.8-9.4c1.2-2.9,3-5.5,5.2-7.7c2.2-2.2,4.8-4,7.7-5.2c2.9-1.2,6-1.9,9.3-1.9c4.5,0,8.5,1,12.1,3.1
			 c3.6,2.1,6.5,5,8.6,8.6c2.1,3.6,3.2,7.8,3.2,12.4c0,3.5-0.6,6.7-1.8,9.6c-1.2,2.9-2.9,5.5-5.1,7.7c-2.2,2.2-4.7,3.9-7.5,5.1
			 C446.7,92.8,443.6,93.4,440.4,93.4z M440.2,88c3.4,0,6.5-0.9,9.2-2.6c2.8-1.7,5-4,6.6-6.9c1.6-2.9,2.5-6.1,2.5-9.6
			 c0-3.6-0.8-6.8-2.4-9.6c-1.6-2.8-3.8-5-6.5-6.5c-2.8-1.6-5.9-2.4-9.5-2.4c-3.5,0-6.6,0.8-9.3,2.5c-2.8,1.6-5,3.8-6.6,6.6
			 c-1.6,2.8-2.5,6-2.5,9.5c0,3.6,0.8,6.8,2.5,9.7c1.6,2.9,3.9,5.1,6.7,6.8C433.6,87.2,436.7,88,440.2,88z"/>
		 <path d="M494.2,93c-0.9,0-1.5-0.1-2-0.3c-0.5-0.2-0.9-0.6-1.2-1.3l-18.8-42.5c-0.5-1.1-0.5-1.9-0.2-2.4c0.4-0.5,1-0.9,1.9-1.4
			 c0.8-0.4,1.5-0.5,2.1-0.4c0.6,0.2,1.2,0.7,1.6,1.6l16.7,40.1l16.4-38.8c0.4-0.9,0.9-1.8,1.5-2.5c0.6-0.7,1.6-0.7,3,0
			 c1.4,0.7,2,1.4,1.8,2.2c-0.2,0.8-0.4,1.6-0.8,2.3l-18.6,41.6c-0.3,0.6-0.7,1-1.2,1.3C495.8,92.9,495.1,93,494.2,93z"/>
		 <path d="M569.3,92.9c-0.9,0-1.6-0.2-2-0.7c-0.4-0.5-0.7-1.3-0.8-2.6v-6.7c-0.7,1.3-1.9,2.7-3.5,4.2c-1.6,1.6-3.6,2.9-6.1,4.1
			 s-5.5,1.8-9,1.8c-3.3,0-6.3-0.6-9.1-1.9c-2.8-1.2-5.2-3-7.2-5.1c-2.1-2.2-3.7-4.8-4.8-7.6c-1.2-2.9-1.8-6.1-1.8-9.5
			 c0-3.3,0.6-6.3,1.8-9.1c1.2-2.8,2.8-5.3,4.9-7.5c2.1-2.2,4.5-3.9,7.3-5.1c2.8-1.2,5.8-1.9,9-1.9c3.1,0,5.8,0.4,8,1.2
			 c2.2,0.8,4.2,1.9,5.9,3.3c1.7,1.4,3.2,3,4.7,4.9V49c0-1.3,0.2-2.3,0.7-2.9c0.4-0.6,1.2-0.9,2.2-0.9c0.7,0,1.2,0.1,1.5,0.4
			 c0.4,0.2,0.6,0.6,0.8,1.1c0.2,0.5,0.2,1.3,0.2,2.2v40.6c0,0.8-0.1,1.4-0.2,1.9c-0.2,0.5-0.5,0.9-0.8,1.1
			 C570.5,92.8,569.9,92.9,569.3,92.9z M549,87.8c3.2,0,6.1-0.8,8.7-2.4c2.6-1.6,4.7-3.9,6.2-6.7c1.5-2.8,2.3-6,2.3-9.6
			 c0-3.8-0.8-7.1-2.4-9.9c-1.6-2.8-3.7-5-6.3-6.5c-2.6-1.6-5.5-2.4-8.7-2.4c-3.7,0-6.9,0.8-9.6,2.5c-2.7,1.7-4.9,3.9-6.4,6.8
			 c-1.5,2.8-2.3,6.1-2.3,9.8c0,3.5,0.8,6.7,2.3,9.5c1.5,2.8,3.7,5,6.5,6.6S545.3,87.8,549,87.8z"/>
		 <path d="M585,46.6l8.9,0.2l13.9-0.2c0.7,0,1.3,0.1,1.8,0.2c0.4,0.2,0.8,0.4,1,0.8c0.2,0.4,0.3,0.9,0.3,1.6c0,0.7-0.1,1.3-0.2,1.8
			 c-0.2,0.4-0.5,0.7-0.8,0.9c-0.4,0.2-1,0.2-1.7,0.2L593.9,52l-9.1,0.2c-1.1,0-1.8-0.2-2.2-0.6c-0.4-0.4-0.6-1.2-0.6-2.2
			 c0-1,0.2-1.7,0.7-2.1C583.1,46.8,583.9,46.6,585,46.6z M607.2,93c-3,0-5.5-0.3-7.5-0.8c-2-0.5-3.7-1.4-5-2.5
			 c-1.3-1.1-2.2-2.6-2.7-4.5c-0.5-1.9-0.8-4.2-0.8-7v-46c0-0.8,0.1-1.4,0.2-1.9c0.2-0.4,0.5-0.8,0.8-1c0.4-0.2,0.9-0.3,1.6-0.3
			 c0.7,0,1.3,0.1,1.8,0.2c0.4,0.2,0.8,0.4,0.9,0.9c0.2,0.4,0.3,1,0.3,1.7v46.5c0,2.1,0.2,3.7,0.5,5c0.4,1.2,0.9,2.1,1.7,2.8
			 c0.8,0.6,1.8,1,3.1,1.1c1.3,0.2,2.9,0.2,4.8,0.2c0.8,0,1.4,0.1,1.9,0.2c0.4,0.2,0.8,0.5,1,0.9c0.2,0.4,0.3,1,0.3,1.7
			 c0,0.7-0.1,1.3-0.3,1.7c-0.2,0.4-0.5,0.7-1,0.9C608.5,92.9,607.9,93,607.2,93z"/>
		 <path d="M625,30.9c-0.9,0-1.7-0.1-2.2-0.4c-0.5-0.3-0.9-0.7-1.2-1.3c-0.2-0.6-0.3-1.4-0.3-2.3c0-0.9,0.1-1.7,0.3-2.2
			 c0.2-0.5,0.6-0.9,1.2-1.1c0.6-0.2,1.3-0.4,2.2-0.4c0.9,0,1.7,0.1,2.2,0.4c0.5,0.3,0.9,0.7,1.2,1.2c0.2,0.6,0.3,1.3,0.3,2.3
			 c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.6,1-1.2,1.2C626.7,30.8,626,30.9,625,30.9z M625,93c-0.7,0-1.2-0.1-1.7-0.3
			 c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.2-1-0.2-1.8V48.2c0-0.7,0.1-1.3,0.2-1.8c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,1-0.2,1.8-0.2
			 c0.7,0,1.3,0.1,1.7,0.3c0.4,0.2,0.7,0.5,0.8,0.9c0.2,0.4,0.2,1.1,0.2,1.9v41.7c0,0.7-0.1,1.3-0.2,1.8c-0.2,0.4-0.5,0.7-0.9,0.9
			 C626.3,92.9,625.7,93,625,93z"/>
		 <path d="M664.1,93.4c-3.3,0-6.5-0.6-9.4-1.9c-2.9-1.3-5.5-3-7.8-5.2c-2.2-2.2-4-4.8-5.2-7.7c-1.3-2.9-1.9-6.1-1.9-9.4
			 c0-3.4,0.6-6.5,1.8-9.4c1.2-2.9,3-5.5,5.2-7.7c2.2-2.2,4.8-4,7.7-5.2c2.9-1.2,6-1.9,9.3-1.9c4.5,0,8.5,1,12.1,3.1
			 c3.6,2.1,6.5,5,8.6,8.6c2.1,3.6,3.2,7.8,3.2,12.4c0,3.5-0.6,6.7-1.8,9.6c-1.2,2.9-2.9,5.5-5.1,7.7c-2.2,2.2-4.7,3.9-7.5,5.1
			 C670.4,92.8,667.3,93.4,664.1,93.4z M663.9,88c3.4,0,6.5-0.9,9.2-2.6c2.8-1.7,5-4,6.6-6.9c1.6-2.9,2.5-6.1,2.5-9.6
			 c0-3.6-0.8-6.8-2.4-9.6c-1.6-2.8-3.8-5-6.5-6.5c-2.8-1.6-5.9-2.4-9.5-2.4c-3.5,0-6.6,0.8-9.3,2.5c-2.8,1.6-5,3.8-6.6,6.6
			 c-1.6,2.8-2.5,6-2.5,9.5c0,3.6,0.8,6.8,2.5,9.7c1.6,2.9,3.9,5.1,6.7,6.8C657.3,87.2,660.4,88,663.9,88z"/>
		 <path d="M702,92.9c-0.7,0-1.2-0.1-1.6-0.3c-0.4-0.2-0.7-0.5-0.8-1c-0.2-0.4-0.2-1-0.2-1.8v-42c0-0.7,0.1-1.2,0.2-1.5
			 c0.2-0.4,0.5-0.6,0.9-0.8c0.4-0.2,1-0.2,1.6-0.2c0.5,0,0.9,0.1,1.2,0.1c0.3,0.1,0.6,0.2,0.8,0.5s0.4,0.5,0.5,0.8
			 c0.1,0.3,0.2,0.7,0.2,1.2v5.9c0.9-1.1,1.8-2.2,2.9-3.2c1.1-1,2.2-2,3.5-2.8c1.3-0.8,2.7-1.5,4.3-2c1.6-0.5,3.3-0.7,5.2-0.7
			 c2.9,0,5.5,0.5,7.9,1.4c2.4,1,4.6,2.4,6.4,4.2c1.8,1.8,3.2,4.1,4.2,6.7c1,2.6,1.5,5.7,1.5,9.1V90c0,0.7-0.1,1.3-0.2,1.8
			 c-0.2,0.4-0.5,0.7-0.9,0.9c-0.4,0.2-1,0.2-1.7,0.2c-0.7,0-1.3-0.1-1.8-0.3c-0.4-0.2-0.7-0.5-0.9-1c-0.2-0.4-0.2-1-0.2-1.8V66.4
			 c0-3.3-0.5-6.1-1.6-8.5s-2.7-4.3-4.8-5.6c-2.1-1.3-4.8-2-8.1-2c-3.2,0-6,0.7-8.3,2c-2.3,1.3-4.1,3.2-5.4,5.6
			 c-1.3,2.4-1.9,5.2-1.9,8.5V90c0,0.7-0.1,1.3-0.2,1.8c-0.2,0.4-0.5,0.7-0.9,0.9C703.3,92.8,702.7,92.9,702,92.9z"/>
		 <path d="M769.8,93.3c-1.5,0-3.1-0.1-4.6-0.4c-1.6-0.3-3.1-0.7-4.5-1.3c-1.4-0.6-2.8-1.2-4-2c-1.2-0.8-2.2-1.6-2.9-2.5
			 c-0.5-0.7-0.8-1.2-1-1.7c-0.2-0.5-0.2-0.9,0-1.3c0.2-0.4,0.5-0.8,1-1.1c0.7-0.8,1.4-1.1,2.1-1c0.7,0.1,1.5,0.8,2.6,1.9
			 c0.6,0.7,1.4,1.3,2.3,1.9c0.9,0.6,1.9,1,3,1.5c1.1,0.4,2.1,0.7,3.2,0.9c1.1,0.2,2.1,0.3,3,0.3c3.7,0,6.6-0.7,8.9-2.1
			 c2.3-1.4,3.4-3.4,3.4-5.9c0-1.3-0.2-2.4-0.7-3.4c-0.5-1-1.1-1.8-1.9-2.4c-0.8-0.7-1.7-1.2-2.8-1.8c-1-0.5-2.2-0.9-3.4-1.3
			 c-1.2-0.4-2.5-0.7-3.8-1c-1.9-0.5-3.7-1-5.5-1.5c-1.8-0.6-3.5-1.3-5-2.2c-1.5-0.9-2.7-2-3.5-3.4c-0.9-1.4-1.4-3.2-1.4-5.4
			 c0-2.5,0.7-4.7,2-6.6c1.3-1.9,3.1-3.3,5.5-4.4c2.3-1.1,5-1.6,8.1-1.6c1.5,0,3,0.1,4.3,0.4c1.3,0.2,2.6,0.6,3.8,1
			 c1.2,0.5,2.3,1,3.3,1.7c1,0.6,2,1.4,2.8,2.2c0.8,0.7,1.2,1.4,1.3,2c0.1,0.6-0.2,1.2-0.7,1.9c-0.6,0.7-1.2,1-1.9,1.1
			 c-0.7,0.1-1.4-0.2-2.1-1c-1.1-1.1-2.2-1.9-3.4-2.5c-1.2-0.6-2.4-1.1-3.6-1.5c-1.2-0.3-2.5-0.5-3.9-0.5c-1.9,0-3.7,0.3-5.2,1
			 c-1.5,0.6-2.7,1.5-3.6,2.7c-0.8,1.2-1.2,2.5-1.2,4c0,1.1,0.3,2.1,0.9,2.9c0.6,0.8,1.3,1.4,2.2,1.9c0.9,0.5,2,1,3.3,1.4
			 c1.3,0.4,2.6,0.8,4,1.2c2,0.5,4,1.1,6.1,1.8c2.1,0.6,4,1.5,5.7,2.5c1.7,1,3.1,2.4,4.2,4.1c1.1,1.7,1.6,3.8,1.6,6.5
			 c0,4-1.7,7.2-5,9.6C779.3,92.1,775,93.3,769.8,93.3z"/>
	 </g>
	 </svg>
	)
}

export default PrimaryLogo;
