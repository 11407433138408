import React from 'react'



const Home = (props) => {

	return (
		<div>
			<h2>Here</h2>
		</div>
	)
}
export default Home;