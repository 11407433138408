import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ThankYou from './components/Confirmation'
import Checkout from './pages/Checkout'
import Home from './pages/Home'

function App() {
	
    return (
        <Router>
            <Routes>
						<Route strict path="/" element={<Home />} />
                <Route strict path="/stream" element={<Checkout system={{ 
							logo:"/logos/stream.svg", 
							name:"Zeal Stream", 
							powered:true, 
							identifier:"zs", 
							theme:{ primary:"rgb(72, 147, 247)", secondary:"rgb(37,71,120)", label:"rgb(221,156,134)" } 
						}} />} />
                <Route strict path="/launch" element={<Checkout system={{ 
							logo:"/logos/launch.svg", 
							name:"Zeal Launch", 
							powered:true, 
							identifier:"zl", 
							theme:{ primary:"rgb(227,123,0)", secondary:"rgb(184,57,14)", label:"rgb(221,156,134)" } 
						}} />} />
                <Route path="/success" element={<ThankYou />} />
            </Routes>
        </Router>
    )
}

export default App
