import React, { useContext, useEffect } from 'react'
import dayjs from 'dayjs'

// import PlansContext from '../context/Product'


const ThankYou = () => {

    // const { plans } = useContext(PlansContext)
    // const { purchasedPlan, setPurchasedPlan } = useContext(PurchasedPlanContext)

    // const setPlanToDisplay = () => {
    //     const queryString = window.location.search
    //     const urlParams = new URLSearchParams(queryString)
    //     const purchasedPlanParam = urlParams.get('plan_purchased')
    //     // setPurchasedPlan(plans.filter(plan => plan.name === purchasedPlanParam)[0])
    // }

    // useEffect(() => setPlanToDisplay(), [])

    // purchased plan can be true but not an object...we need to ensure this object is loaded into state before loading this into ui
    return (
        <>

            {/* <div className="container py-5 text-center thank-you-text">

                <h1 className="pt-5 pb-2 fw-bold">Thank You!</h1>

                <div className="pb-2">
                    <p><span className="fw-bold">Check Your Email!</span> We are sending you an email to confirm your email address and begin the registration process.</p>
                    <p>Below you will find your order details.</p>
                </div>

                <div className="py-2">
                    <h5 className="fw-bold">Plan Purchased:</h5>
                    <p>{purchasedPlan.name}</p>
                </div>

                <div className="py-2">
                    <h5 className="fw-bold">Amount Charged Today ({dayjs().format('DD/MM/YYYY')}):</h5>
                    <p>{purchasedPlan.name === "Personal" ? "0.99¢ (Initial Setup Fee)" : `$${purchasedPlan.metadata.price_for_fe}`}</p>
                </div>

                <div className="pt-2">
                    <h5 className="fw-bold">Recurring Amount to be Charged:</h5>
                    <p>{purchasedPlan.name === "Personal" ? "FREE" : `$${purchasedPlan.metadata.price_for_fe}`}</p>
                </div>

            </div> */}
        </>
    );

}

export default ThankYou