import { CheckoutContainer, CheckoutWrapper } from "../styles/Checkout/main";
import "../styles/Checkout/main.scss";

const Layout = ({ children }) => {
	


	return (
		<main>
			<CheckoutContainer>
				<CheckoutWrapper>
					{children}
				</CheckoutWrapper>
			</CheckoutContainer>
		</main>
	)
}

export default Layout;